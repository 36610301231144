@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --space: 5px;
}
html {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: auto;
  font-smooth: auto;
  -webkit-font-smoothing: auto;
}

* {
  /* firefox only styles */
  scrollbar-width: thin;
}

/* Styles for Windows devices */
/* TODO(dmitry): validate this hack and uncomment */
/* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { */
/* Windows-specific styles go here */
::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
  height: 5px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

.no-scrollbar-x::-webkit-scrollbar {
  height: 0px;
}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

html,
body,
#root,
#root > div {
  margin: 0;
  width: 100%;
  height: 100%;
}

html body {
  font-family: "Roboto", sans-serif;
  background: white;
  -webkit-font-smoothing: auto;
}

body {
  font-size: 0.875rem;
  line-height: 1.43;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.portal-hide-scrollbars::-webkit-scrollbar {
  width: 0;
}

.portal-flex {
  flex: 1 1 auto;
}

/* Elements Pages */
.portal-pages__header {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background: rgba(0, 0, 0, 0.05);
}

.portal-pages__header-demo {
  max-width: 400px;
  margin-top: 0;
  margin-bottom: 150px;
  padding: 0px 48px;
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
}

@keyframes portal-pages__content-inner {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

svg .node text {
  cursor: pointer;
  font-weight: 500;
  user-select: none;
}

svg .node {
  cursor: pointer;
}

svg .cluster text {
  font-weight: 500;
}

.selected path,
.selected polygon,
.hovered path,
.hovered polygon {
  stroke: #43a7ea;
  cursor: pointer;
}

.intercom-lightweight-app .intercom-launcher,
.intercom-app iframe.intercom-launcher-frame {
  transform: scale(0.6) translateX(-60%) translateY(60%);
  /* do not allow to click on support within modal */
  z-index: 1299;
}

.intercom-lightweight-app {
  z-index: 1299 !important;
}

/* because emotion can't handle parse this pseudo selector */
.input-with-pencil-cursor::-webkit-file-upload-button {
  cursor: url("../icons/pencil.svg") 0 20, auto;
}

@font-face {
  font-family: "Spaces";
  src: url("./dot-font.otf");
  unicode-range: U+0-20;
}

[hidden] {
  display: none;
}
